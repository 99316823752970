import React, { useEffect, useState } from 'react'
import classes from '@src/styles/Page404.module.css'
import Icon404 from '@src/assets/404.svg'
import { useRouter } from 'next/router'
import { segmentTrack } from '@src/functions/segment'

function Page404() {
  const history = useRouter();
  const [isAppSite, setIsAppSite] = useState(false);
  const paths = [
    "alerts",
    "portal",
    "confirmation",
    "review",
    "booking",
    "bookings",
    "tasks",
    "bids",
    "get_bids",
    "get_tasks",
    "task_details",
    "bid_details",
    "complete_details",
    "quote_details",
    "account",
    "sares_regis",
    "provideragreement2",
    "service-provider-policy",
    "corporateserviceagreement"
  ];

  const wind = typeof window !== "undefined" ? window : null;
  const url = wind?.location?.href ?? "";
  const path = wind?.location?.pathname ?? "";
  const isApp = path && paths.includes(path?.split("/")?.[1]);

  const handleNavigate = () => {
    if (isAppSite) {
      const appUrl = url.replace(process.env.NEXT_PUBLIC_HOSTING_URL_PRIMARY ?? "", process.env.NEXT_PUBLIC_REDIRECT_URL ?? "");
      if (typeof window !== "undefined") {
        window.open(appUrl, "_self");
      }
    } else {
      history.push("/");
    }
  };
  useEffect(() => {
    if (isApp) {
      setIsAppSite(Boolean(isApp));
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    window.onerror = (_e1, _e2, _e3, _e4, { message, stack }: any) => {
      // eslint-disable-next-line no-console
      console.error('Global App Error', { message, stack })
      segmentTrack('Global App Error', { message, stack })
    }
  }, [isApp]);

  return (
    <section className={classes.container}>
      <div className={classes.mainContent}>
        <img src={Icon404.src} alt='404 Icon' />
        <h2 className={classes.header}>{isAppSite ? "This page has moved!" : "Whoops, This page does not exist!"}</h2>
        <p className={classes.description}>
          {isAppSite ? (
            "Please click on the button below to navigate to the new page at app.getdimension.com"
          ) : (
            `Unfortunately, we can't seem to find the page you're looking for.`
          )}
        </p>
        <button onClick={handleNavigate} className={classes.primaryButton}>
          {isAppSite ? "Go to New Page" : "Go Back home"}
        </button>
      </div>
    </section>
  )
}

export default Page404
